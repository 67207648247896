<template>
    <div id="loader_container" class="loader-container" :class="{ loader: true, fadeout: !isLoading }">
        <!-- <video autoplay playsinline :src="loaderVideo" type="video/mp4"></video> -->
        <video autoplay muted playsinline>
          <source :src="loaderVideo" type="video/mp4">
        </video>
        <img :src="logo">
    </div>
</template>

<script>
import loaderVideo from "../assets/videos/loader_logo.mp4"
import logo from '../assets/images/logo.png'

export default {
  props: ["isLoading"],
  data () {
    return {
      logo,
      loaderVideo
    }
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>
