import { createRouter, createWebHistory } from 'vue-router'

const routes =  [
  {
    path: "/tutorials",
    name: "tutorials",
    component: () => import("../components/TutorialsList")
  },
  {
    path: "/tutorials/:id",
    name: "tutorial-details",
    component: () => import("../components/Tutorial")
  },
  {
    path: "/add",
    name: "add",
    component: () => import("../components/AddTutorial")
  },
  {
    path: "/",
    name: "page-top",
    component: () => import("../pages/PageTop")
  },
  {
    path: "/services",
    name: "page-services",
    component: () => import("../pages/PageServices"),
    children: [
      {
        path: "/services/meo",
        name: "page-meo",
        component: () => import("../pages/PageMeo"),
        children: [
          {
            path: "/services/meo/marketing",
            name: "subpage-marketing",
            component: () => import("../pages/SubPageMarketing")
          },
          {
            path: "/services/meo/rival",
            name: "subpage-rival",
            component: () => import("../pages/SubPageRival")
          }
        ]
      },
      {
        path: "/services/homepage",
        name: "page-homepage",
        component: () => import("../pages/PageHomePage"),
        children: [
          {
            path: "/services/homepage/template",
            name: "subpage-template",
            component: () => import("../pages/SubPageTemplate")
          },
          {
            path: "/services/homepage/hpservice",
            name: "subpage-hpservice",
            component: () => import("../pages/SubPageHpService")
          }
        ]
      },
      {
        path: "/services/apps",
        name: "page-app",
        component: () => import("../pages/PageApp")
      },
      {
        path: "/services/metaverse",
        name: "page-meta",
        component: () => import("../pages/PageMeta")
      },
      {
        path: "/services/ses",
        name: "page-ses",
        component: () => import("../pages/PageSes")
      }
    ]
  },
  {
    path: "/company",
    name: "page-company",
    component: () => import("../pages/PageCompany")
  },
  {
    path: "/contact",
    name: "page-contact",
    component: () => import("../pages/PageContact")
  },
  {
    path: "/privacypolicy",
    name: "page-privacy",
    component: () => import("../pages/PagePrivacy")
  },
  {
    path: '/drone',
    redirect: '/services/drone'
  },
  {
    path: '/about-us',
    redirect: '/company'
  },
  {
    path: '/digital-marketing',
    redirect: '/services/meo/marketing'
  },
  
  {
    path: '/rival-research',
    redirect: '/services/meo/rival'
  },
  {
    path: '/service',
    redirect: '/services'
  },
  {
    path: '/thanks',
    name: "page-thanks",
    component: () => import("../pages/PageThanks")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
