<template>
  <v-app>
    <v-main>
      <the-loader :isLoading="this.isLoading"></the-loader>
      <div v-if="!isLoading">
        <div class="dropdown">
          <p @click="googleTranslateElementInit()" class="dropbtn">LANGUAGE</p>
          <div class="dropdown-content">
            <button id="google_translate_element" ></button>
          </div>
        </div>
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import the_loader from "./components/TheLoader.vue"

export default {
  name: 'App',
  data: () => ({
    isLoading: true
  }),
  components:{
    'the-loader': the_loader
  },
  created (){
    setTimeout(() => {
      this.isLoading = false
    }, 4300)    
  },
  methods: {
    googleTranslateElementInit() {
      window.google.translate.TranslateElement({pageLanguage: 'jp'}, 'google_translate_element');
    }
  },
}
</script>
